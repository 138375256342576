/*
    Site Wide Customizations
*/
body {
    background-image: url('../images/site_content_bg.jpg');
    height: 100%;
}

.form-check-input:checked {
    background-color: $red;
    border-color: $red;
}

.btn:hover, .btn:focus {
    color: $white;
    background-color: $red;
    border-color: $red;
    box-shadow: none;
}

#wrapper {
    background-color: $white;
    border-left: 10px solid $white;
    border-right: 10px solid $white;
    min-height: 100%;
    height: auto !important;
    margin-bottom: -10em;
    width: 80%;
}

/*
    Header Customizations
*/
header {
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    #top-bar, #bottom-bar {
        display: flex;
        flex-direction: row;
    }
}

#top-bar {
    width: 100%;
    height: 65px;
    margin: auto;
    background-color: $white;

    .container-fluid {
        width: 768px;
        display: flex;
        padding-left: 0;
        padding-right: 0;
    }

    #asa-logo {
        width: 175px;
        height: 50px;
        text-indent: -10000px;
        margin-top: 5px;
        background: url(../images/site_sprite.png) 0 0 no-repeat scroll transparent;
    }

    ul.socials, ul.meta_nav {
        display: flex;
        flex-direction: row;
    }

    ul.socials li, ul.meta_nav li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        font-family: "Bebas Neue", sans-serif;
        align-items: flex-end;

        a {
            color: $gray;
            text-transform: uppercase;
            font-size: 1.15rem;

            &:visited {
                color: $gray-dark;
            }
        }
    }

    ul.socials li {
        margin-right: 5px;
    }

    ul.meta_nav li {
        margin-right: 10px;

        a:hover {
            color: $gray-hover;
            text-decoration: none;
        }
    }

    ul.socials a.facebook_btn {
        width: 38px;
        height: 38px;
        text-indent: -10000px;
        background: url('../images/site_sprite.png') 0 -60px no-repeat scroll transparent;

        &:hover {
            background: url('../images/site_sprite.png') -50px -60px no-repeat scroll transparent;
        }
    }

    ul.socials a.linkedin_btn {
        width: 38px;
        height: 38px;
        text-indent: -10000px;
        background: url('../images/site_sprite.png') 0 -108px no-repeat scroll transparent;

        &:hover {
            background: url('../images/site_sprite.png') -50px -108px no-repeat scroll transparent;
        }
    }
}

#bottom-bar {
    width: 100%;

    .container-fluid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #logo {
        min-width: 120px;
        height: 50px;
        margin-right: 10px;
        padding: 5px 0;
    }

    #user-menu, #browse-menu, #search-box button {
        &:hover, &:focus {
            background-color: $red;
            color: $white;
            border-color: $red;
            box-shadow: none;
        }
    }

    #navbar-content li.nav-item a.nav-link {
        &:hover, &:focus {
            background-color: $red;
            color: $white;
        }
    }

    #search-box-input:focus {
        box-shadow: none;
        border-color: inherit;
    }

    ul.dropdown-menu li {
        a {
            color: $gray;
            background: url('../images/drop_nrm_arrow.png') 5px no-repeat;
            text-transform: uppercase;
            padding: 0 0 0 20px;

            &:hover, &:focus {
                color: $red;
                background: url('../images/drop_ovr_arrow.png') 5px no-repeat;
            }
        }
    }

    .dropdown-header {
        border-bottom: 1px solid $off-white;
        margin-bottom: 0.5rem;

        h6 {
            font-size: 1.25rem;
        }

        li.text-muted {
            font-size: 1rem;

            #counts-block {
                color: $gray;
            }
        }
    }

    .navbar-toggler {
        max-height: 40px;

        &:hover, &:focus {
            background-color: $red;
            border-color: $red;
            box-shadow: none;
        }
    }
}

/*
    Homepage Customizations
*/
#sidebar section {
    h2.h5 {
        border-bottom: 5px solid $gray-dark;
        font-family: "Bebas Neue", sans-serif;
    }

    .list-group a.list-group-item {
        background: url('../images/red_arrow.png') 10px 15px no-repeat;
        padding: 0.5rem 1rem 0.5rem 1.75rem;
        color: $gray;
        font-family: Times, serif;

        span.ms-3 {
            font-weight: bolder;
        }

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }
}

/*
    Browse & Description Customizations
*/
article.search-result {
    .text-primary {
        color: $red !important;
    }
}

.more-link {
    color: transparent;
    text-indent: -79px;
    display: inline-block;
    position: relative;

    &:hover {
        color: transparent;
    }
}

.more-link::after {
    content: '»';
    color: $red;
    text-indent: 0px;
    background-color: $off-white;
    border: 1px solid $gray-dark;
    border-radius: 5px;
    padding: 0 5px;
    display: inline-block;
    vertical-align: middle;
}

.less-link {
    color: transparent;
    text-indent: -79px;
    display: inline-block;
    position: relative;

    &:hover {
        color: transparent;
    }
}

.less-link::after {
    content: '«';
    color: $red;
    text-indent: 0px;
    background-color: $off-white;
    border: 1px solid $gray-dark;
    border-radius: 5px;
    padding: 0 5px;
    display: inline-block;
    vertical-align: middle;
}

#sidebar .accordion .accordion-item h2.accordion-header {
    font-family: inherit;

    button {
        background-color: $black;
        color: $white;
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
            content: '\f107';
            font-family: FontAwesome;
            color: $white;
        }
    }
}

#sidebar h2.d-grid button {
    font-family: "Bebas Neue", sans-serif;
    border-bottom: 5px solid $gray-dark;

    &:hover, &:focus {
        background-color: unset;
        border-color: unset;
        color: unset;
    }
}

.page-link {
    color: $red;
}

a {
    color: $gray;

    &:hover {
        color: $red;
        text-decoration: none;
    }
}